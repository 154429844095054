<template>
  <component :is="layout">
    <div class="row fadeInUp" role="main">
      <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
        <h5>Payment history</h5>
        <div class="card border-0 shadow-sm mb-4 fadeInUp">
          <div class="card-body">
            <div class="c-loader" v-if="loading">
              <div class="c-loader__content">
                <div class="c-loader__spinner"></div>
              </div>
            </div>
            <paymentHistory :invoices="invoices" v-else></paymentHistory>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import Default from "@/layouts/default.vue";
import paymentHistory from "@/components/Account/PaymentHistory.vue";

export default {
  components: {
    Default,
    paymentHistory,
  },
  data: function() {
    return {
      layout: "Default",
      loading: true,
      invoices: [],
    };
  },
  mounted: function() {
    jsonAPI.get("/organization/subscription/history").then((response) => {
      this.invoices = response.data.data;
      this.loading = false;
    });
  },
};
</script>
