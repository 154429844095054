<template>
  <div>
    <table class="table payment-history-table" v-if="invoices.length">
      <thead>
        <tr>
          <th>Date</th>
          <th class="text-right">Total</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="invoice in invoices" :key="invoice.id">
          <td>
            <div class="table-spacer">{{ invoice.date }}</div>
          </td>
          <td class="text-right">
            <div class="table-spacer">{{ invoice.total }}</div>
          </td>
          <td class="text-right">
            <a
              :href="'/invoices/' + invoice.id"
              class="btn btn-default btn-sm"
              v-if="invoice.id"
              >Download</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <em v-else>No invoices found</em>
  </div>
</template>

<script>
export default {
  props: {
    invoices: {
      type: Array,
      required: true,
    },
  },
};
</script>
